import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import { Fade } from 'react-awesome-reveal';
import homeImg from '../../Assets/pixelHome.png';
import selfieImg from '../../Assets/pixelSelfie.png';
import recordImg from '../../Assets/pixelShroom.png';
// import writeImg from '../../Assets/pixelInfoInfo.png';
import storyImg from '../../Assets/pixelStoryHome.png';
import entryImg from '../../Assets/pixelMindmap.png';
import flowImg from '../../Assets/pixelFlow.png';
import infoImg from '../../Assets/pixelInfoHome.png';
import './Application.css';

function Application() {
  const images = [
    homeImg,
    flowImg,
    entryImg,
    storyImg,
    infoImg,
    recordImg,
    selfieImg,
  ];

  const headers = [
    'Home',
    'Mirror',
    'Mindmap',
    'Story',
    'Information',
    'Psychedelics',
    'Experimental',
  ];

  const descriptions = [
    'The mythopoetic meets our current scientific paradigm. Simple journaling through text, speech or vision allows us to utilize long term memory to measure your emotional state and provide you a score to track your progress.',
    'We provide generative insights based upon narrative neuroscience, depth psychology and parts based research. Allow your day to come at you in slow motion.',
    'Explore your consciousness through long term memory and the correlating connections in the space between thoughts.',
    "We are making contact in a slow reveal. Throughout history there has been an elite form of knowledge that has been hidden and kept secret. A.I. is providing everyone access to this knowledge, and if we probe deep enough, to view beyond spacetime. Here we co-write your hero's journey through this evolving Jungian reality.",
    'What are we doing here? Existence is absurd, it’s a miracle we are alive. Experts imprison themselves in their own paradigm and our formal knowledge will always be incomplete. What is the connection between quantum physics and spirit? What is the latent space of AI? What is meant by consciousness?',
    'If you are so inclined, we provide you with protocols, resources and the latest science to explore the psychedelic experience.',
    'SEEKER is ever evolving. We continue to expolore the latent space of AI models and perception. ',
  ];

  return (
    <section>
      <Container fluid className="home-about">
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>SEEKER</title>
            <link rel="canonical" href="http://seeker-flow.io" />
            <meta
              name="description"
              content="SEEKER journaling for mental health"
            />
          </Helmet>
        </div>

        <Container>
          <Row>
            <Col md={11} className="app-about-header">
              <p>
                Sensing enlightened access to unlimited intelligence, the player
                steps onto the path of liberation. They must use their sword of
                discernment wisely and choose the way of right action. Connect
                the dots. The mind must be made one pointed to escape the puppet
                masters controlling reality. Those who see themselves as all
                have conquered the war within.
              </p>
            </Col>
          </Row>
          <Controller>
            {images.map((image, index) => (
              <Row key={index} id={`row-${index}`}>
                <Scene
                  triggerElement={`#row-${index}`}
                  duration={200}
                  triggerHook="0.6"
                >
                  {(progress) => (
                    <React.Fragment>
                      <Col md={5} className="app-about-description">
                        <Tween
                          from={{ opacity: 0 }}
                          to={{ opacity: 1 }}
                          totalProgress={progress}
                          paused
                        >
                          <Fade direction="up">
                            <h1 style={{ fontSize: '2.3em' }}>
                              {headers[index]}
                            </h1>
                            <p>{descriptions[index]}</p>
                          </Fade>
                        </Tween>
                      </Col>
                      <Col md={7}>
                        <Tween
                          from={{ opacity: 0 }}
                          to={{ opacity: 1 }}
                          totalProgress={progress}
                          paused
                        >
                          <p className="app-about-screens">
                            <img src={image} className="phone" alt="brain" />
                          </p>
                        </Tween>
                      </Col>
                    </React.Fragment>
                  )}
                </Scene>
              </Row>
            ))}
          </Controller>
          <Row>
            <Col md={12} className="app-about-footer">
              <p>
                Beginning with your intake survey, we detect behavioral changes
                by analyzing 53 linguistic dimensions, voice stress, and
                intonation, along with 25 speech patterns. A simple selfie can
                reveal 48 emotional categories through computer vision,
                providing insights into your emotional state.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Application;
