import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
//import homeImg from '../../Assets/home_page.png';
import homeImg from '../../Assets/present3.png';
import therapistImg from '../../Assets/therapist.png';
import flowImg from '../../Assets/man.png';
import appleImg from '../../Assets/apple.png';
import googleImg from '../../Assets/google.png';
import screensImg from '../../Assets/Seeker.png';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import './Home.css';

const LinkText = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-decoration-line: underline;
  cursor: pointer;
  color: white; /* Default color */

  &:hover {
    color: #1ce783; /* Hover color */
  }
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  font-weight: bold;
  text-decoration-line: underline;
  cursor: pointer;
  color: white; /* Default color */

  &:hover {
    color: #1ce783; /* Hover color */
  }
`;

function Home() {
  const openLink = (url) => {
    window.open(url, '_blank');
  };

  const [email, setEmail] = useState('');
  const [platform, setPlatform] = useState('');
  const [showWaitlistPopup, setShowWaitlistPopup] = useState(false);

  // Event handler for email submission
  function handleEmailSubmit() {
    // Simple email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    // Set platform to android automatically
    const platform = 'android';

    fetch('https://seeker-backend-prod-rqicq2qnmq-uc.a.run.app/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, platform }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);
        } else {
          setEmail(''); // Clear the email input
          setPlatform(''); // Reset the platform selection
          alert('Thank you for signing up!');
        }
      })
      .catch((error) => {
        alert('The beta will open soon.');
        console.error('There was an error!', error);
      });
  }

  const handleGooglePlayClick = () => {
    setShowWaitlistPopup(true);
  };

  return (
    <section>
      <Container fluid className="home-about">
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>SEEKER</title>
            <link rel="canonical" href="http://seeker-flow.io" />
            <meta
              name="description"
              content="SEEKER journaling for mental health therapy"
            />
          </Helmet>
        </div>

        <Container>
          <Row>
            <Col md={6} className="home-about-description order-md-1 order-1">
              <Fade direction="up">
                <h1 className="home-title-main">Optimize Your Mind</h1>
                <h1 className="home-title-sub">Transform Your Self</h1>
              </Fade>
              <div className="app-links-container d-md-block d-none">
                <a
                  href="https://apps.apple.com/us/app/seeker-ai-journal-tracker/id6462017184"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appleImg}
                    className="app-icon"
                    alt="Apple App Store"
                  />
                </a>
                <img
                  src={googleImg}
                  className="google-icon"
                  alt="Google Play Store"
                  onClick={handleGooglePlayClick}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </Col>

            <Col md={6} className="home-about-image order-md-2 order-2">
              <img
                src={homeImg}
                className="appHomeScreen"
                alt="appHomeScreen"
                style={{ width: '100%', height: 'auto' }}
              />
            </Col>
          </Row>
          <Row className="waitlist-app-row order-md-3 order-3">
            <Col md={6}>
              <div className="waitlist-section">
                <div>
                  <h1 style={{ fontSize: '0.7em', fontStyle: 'italic' }}>
                    Android version coming soon
                  </h1>
                  <input
                    type="email"
                    id="waitlistEmail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="waitlist-email-input"
                  />
                  <button
                    onClick={() => handleEmailSubmit()}
                    className="waitlist-button"
                  >
                    Join Android Waitlist
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="order-md-4 order-4">
            <Col md={12}>
              <div className="app-links-container d-md-none d-block">
                <a
                  href="https://apps.apple.com/us/app/seeker-ai-journal-tracker/id6462017184"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appleImg}
                    className="app-icon"
                    alt="Apple App Store"
                  />
                </a>
                <img
                  src={googleImg}
                  className="google-icon"
                  alt="Google Play Store"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-container">
              {/* <Fade direction="up"> */}
              <p className="home-about-mission">
                We believe the future of mental health is in your hands.
              </p>
              {/* </Fade> */}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-screens">
              <img src={screensImg} className="screens" alt="Screens" />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-flourish">
              <p className="home-about-mission">
                SEEKER is the most comprehensive AI built for human flourishing.
              </p>
            </Col>
          </Row>
          <Row className="home-about-doorways">
            <Col md={12}>
              {/* <div style={styles.bookContainer}>
                <StyledLink to="/rabbithole">Experience</StyledLink>
              </div> */}
              <img src={flowImg} className="man" alt="man with flow screen" />
              <p className="home-about-door">
                SEEKER uses advanced psychological modalities and narrative
                neuroscience to place you at the center of your own story,
                tracking your mood and offering personalized insights along the
                way. Our algorithm crafts messages to elevate your mood and
                foster self-actualization over time. Step onto the path of
                knowledge.
              </p>
            </Col>
            {/* <Col md={6}>
              <img src={therapistImg} className="therapist" alt="therapist" />

              <div style={styles.bookContainer}>
                <StyledLink to="/platform">Integration</StyledLink>
              </div>
              <p className="home-about-door2">
                For therapists, SEEKER is a state-of-the-art educational tool at
                your fingertips, revolutionizing your practice by automating
                progress notes and treatment plans. You are able to converse
                about your clients and track their progress through the lens of
                depth psychology, parts work, and narrative therapy.
              </p>
            </Col> */}
          </Row>
          <Row className="home-about-container">
            {/* <Col md={7} className="home-about-invest"> */}
            {/* <p>Interested in investing in SEEKER</p>
              <div style={styles.bookContainer}>
                <LinkText
                  onClick={() =>
                    openLink('https://docsend.com/view/b7zux7svb8k6ie8g')
                  }
                >
                  View our pitch deck here
                </LinkText>
              </div> */}

            {/* </Col> */}
          </Row>
          <Row className="home-about-container">
            <Col md={7} className="home-about-privacy">
              <div style={styles.bookContainer}>
                <StyledLink to="/privacy">Privacy Policy</StyledLink>
              </div>
              <p>
                SEEKER takes data privacy seriously. We only use your data to
                power your experience and deliver your personal insights. We
                will never sell your data to third parties or use your data to
                sell advertising to other companies.
              </p>

              <div style={styles.bookContainer}>
                <StyledLink to="/rabbithole">
                  Our intention with data
                </StyledLink>
              </div>
              <div style={styles.bookContainer}>
                <LinkText
                  onClick={() =>
                    openLink('mailto:info@seeker-flow.io?subject=Hello!')
                  }
                >
                  Get in touch
                </LinkText>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      {showWaitlistPopup && (
        <div className="popup-overlay" style={styles.overlay}>
          <div className="popup-content" style={styles.popup}>
            <button
              onClick={() => setShowWaitlistPopup(false)}
              style={styles.closeButton}
            >
              ×
            </button>
            <h2>Join Android Waitlist</h2>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              style={styles.input}
            />
            <button onClick={handleEmailSubmit} style={styles.submitButton}>
              Join Waitlist
            </button>
          </div>
        </div>
      )}
    </section>
  );
}

const styles = {
  bookContainer: {
    marginBottom: 30,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popup: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    position: 'relative',
    width: '90%',
    maxWidth: '400px',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    border: 'none',
    background: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: '#1ce783',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};
export default Home;
